import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 
    "url", "id", "name", "shopifyUrl", "customTheme",
    "notShopify", "shopifyTheme", "error", "loading"
  ]

  static values = {
    table: Array
  }

  detect(event) {
    event.preventDefault()
    this.shopifyThemeTarget.classList.add("hidden")
    this.notShopifyTarget.classList.add("hidden")
    this.customThemeTarget.classList.add("hidden")
    this.errorTarget.classList.add("hidden")
    this.loadingTarget.classList.remove("hidden")
    
    if (this.urlTarget.value == "") {
      this.loadingTarget.classList.add("hidden")
      return
    }

    let urlName  = "https://" + this.urlTarget.value
    let options = {
        method: "post",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          store_url: urlName
        })
      }


    fetch("https://datajoblist.com/theme_detections", options)
      .then(response => response.json())
      .then(theme => {
        this.loadingTarget.classList.add("hidden")
        if ('error' in theme) {
          this.errorTarget.classList.remove("hidden")
        } else {
          this.displayTheme(theme)
        }
      })
      .catch(error => {
        this.loadingTarget.classList.add("hidden")
        this.errorTarget.classList.remove("hidden")
      })  
  };

  displayTheme(theme) {
    var selectedId = theme["theme_store_id"]
    var theme;

    if (selectedId === "null") {
      this.customThemeTarget.classList.remove("hidden");     
    } else if (selectedId === "not_present"){
      this.notShopifyTarget.classList.remove("hidden")
    } else {
      // Find the theme ID in our themes table
      this.tableValue.forEach(row => {
        if (row["id"] == selectedId) {
          theme = row
        }   
      })

      // Check whether we found the ID in our themes, show as custom otherwise
      if (theme === undefined) {
        this.customThemeTarget.classList.remove("hidden");
      } else {
        this.nameTarget.textContent = theme["name"] 
        this.shopifyUrlTarget.href = theme["url"]
        this.shopifyThemeTarget.classList.remove("hidden")
      }
    }
  }
}


